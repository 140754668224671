import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// get a random value between two numbers
export function getRandomBetween(min, max) {
	return Math.random() * (max - min) + min;
}

// create a simple line SVG from an array of points [[x, y], ...]
export function createSVG(points) {
	let svg = `M`;
	points.forEach((point) => {
		svg = `${svg}${point[0]},${point[1]}L`;
	});
	return svg.slice(0, -1);
}

// converts a 1D array to a 2D array with a chunk size
export function arrayTo2D(array, size = 2) {
	const arrayCoy = [...array];
	const result = [];
	while (arrayCoy.length) result.push(arrayCoy.splice(0, size));
	return result;
}

// flattens an array to 1D
export function deepFlatten(array) {
	return [].concat(
		...array.map((v) => (Array.isArray(v) ? deepFlatten(v) : v))
	);
}

// get a named url parameter
export function getURLParameterByName(query) {
	const url = new URL(window.location.href);
	const parameters = new URLSearchParams(url.search.slice(1));
	return parameters.get(query);
}

// map a value from one range to another
export function convertToRange(value, oldMin, oldMax, newMin, newMax) {
	return (
		((value - oldMin) * (newMax - newMin)) / (oldMax - oldMin) +
		newMin
	);
}

// execute an array of promises, waiting for each to resolve in order
export function promiseSerial(funcs, callback) {
	const array = [];
	funcs
		.reduce(
			(accumulator, func) =>
				accumulator
					.then(func)
					.then((res) => array.push(...res)),
			Promise.resolve([])
		)
		.then(() => callback(array));
}

// split an array into a new array with sub-arrays of a specific size
export function chunk(array, size) {
	const array_ = [...array];
	if (array_.length === 0) {
		return [];
	}
	const head = array_.slice(0, size);
	const tail = array_.slice(size);

	return [head, ...chunk(tail, size)];
}

// get the distance between two points
export function distance(x1, y1, x2, y2) {
	const a = x1 * 1 - x2 * 1;
	const b = y1 * 1 - y2 * 1;

	return Math.sqrt(a * a + b * b);
}

// get unique elements according to a fn
export function uniqueElementsBy(array, fn) {
	return array.reduce((accumulator, v) => {
		if (!accumulator.some((x) => fn(v, x))) accumulator.push(v);
		return accumulator;
	}, []);
}

// return the polar angle between two points
// NOTE: angular circle is W=0; N=PI/2; S=-PI/2, E=±PI
export function angle(x1, y1, x2, y2) {
	const dX = x1 - x2;
	const dY = y1 - y2;
	return Math.atan2(dY, dX);
}

// gets an image src from a graphql query
export function findSrc(data, name, gatsbyImageType = `fluid`) {
	return data.allImageSharp.edges.find(
		(element) =>
			element.node[gatsbyImageType].originalName.split(`.`)[0] ===
			name
	).node[gatsbyImageType].src;
}

// gets a fluid image from a graphql query
export function findFluidGatsbyImage(data, name) {
	return data.allImageSharp.edges.find(
		(element) =>
			element.node.fluid.originalName.split(`.`)[0] === name
	).node.fluid;
}
// create a star array
export function createStars({ rating, numStars: numberStars = 5 }) {
	const star = [`fa`, `star`];
	const halfStar = [`fa`, `star-half-alt`];
	const outline = [`far`, `star`];

	// get the decimal value without the integer
	const decimal = rating - Math.floor(rating);

	// if the decimal is in the middle the range between integers, make a half star
	const halfStarPresent = decimal > 0.25 && decimal < 0.75;

	// if the rating is less than the top of the middle range between integers, floor the rating
	// if it is greater, ceil it
	const stars =
		decimal < 0.75 ? Math.floor(rating) : Math.ceil(rating);

	// create a new array full of empty stars
	const vals = new Array(numberStars).fill(outline);

	// replace each star with a full star, up to the number of stars
	let i = 0;
	for (; i < stars; i++) {
		vals[i] = star;
	}

	// replace the next outline with a half star if needed
	if (halfStarPresent) vals[i] = halfStar;

	// return the JSX
	return vals.map((value, index) => (
		/* eslint-disable react/jsx-filename-extension */
		// eslint-disable-next-line react/no-array-index-key
		<FontAwesomeIcon icon={value} key={index} />
		/* eslint-enable react/jsx-filename-extension */
	));
}

// get a formatted currency string
export function createCurrency({ value }) {
	return `$ ${value.toLocaleString()} `;
}

// returns an integer converted to an ordinal number
export function ordinalSuffix(i) {
	const j = i % 10;
	const k = i % 100;
	if (j === 1 && k !== 11) {
		return `${i}st`;
	}
	if (j === 2 && k !== 12) {
		return `${i}nd`;
	}
	if (j === 3 && k !== 13) {
		return `${i}rd`;
	}
	return `${i}th`;
}

/**
 * Checks whether a point (x, y) lies within an ellipse (h, k)
 *
 * @param x X-coordinate of the point to test
 * @param y Y-coordinate of the point to test
 * @param h X-coordinate of the ellipse to test against
 * @param k Y-coordinate of the ellipse to test against
 * @param rx X-radius of the ellipse to test against
 * @param ry Y-radius of the ellipse to test against
 */
export function withinEllipse({ x, y, h, k, rx, ry }) {
	return (x - h) ** 2 / rx ** 2 + (y - k) ** 2 / ry ** 2 <= 1;
}
