import firebase, { auth } from "firebase";
import "firebase/firestore";

import { FIREBASE_CONFIG } from "../etc/config";
import sortBy from "lodash.sortby";
import uuid from "uuid/v4";

// connect to the db
firebase.initializeApp(FIREBASE_CONFIG);
auth().signInAnonymously().then((creds)=>{
	console.log(creds);
}).catch((err) => {
	console.error(err); // For sentry
})
// const auth = firebase.auth();
// console.log(auth)
const scoresDB = firebase.firestore().collection(`scores`);
const metricsDB = firebase.firestore().collection(`metrics`);

function parseScores(querySnapshot, numResults) {
	const data = querySnapshot.docs.map((doc) => ({
		...doc.data(),
		id: doc.id,
	}));
	const sorted = sortBy(data, `score`);
	const truncated = [];
	for (let i = 0; i < numResults; i++) {
		const pop = sorted.pop();
		if (pop !== undefined) truncated.push(pop);
	}
	return truncated;
}

// get the top n scores from the db, sorted
export async function getScores(numResults = 10) {
	const querySnapshot = await scoresDB.get();
	return parseScores(querySnapshot, numResults);
}

// listen for realtime updates to the scores
export function listenForScores(
	numResults = 10,
	cb = (res) => console.log(res)
) {
	scoresDB.onSnapshot((querySnapshot) => {
		cb(parseScores(querySnapshot, numResults));
	});
}

// save a score to the db
export function saveScore(name, score) {
	const id = uuid();
	scoresDB.doc(id).set({
		name,
		score: score > 99999 ? 99999 : score,
	});
	return id;
}

// save the user metrics to the db
export async function saveUser(state) {
	const metrics = {
		...state,
		timestamp: Date.now(),
	};
	await metricsDB.doc(uuid()).set(metrics);
}

export async function getMetrics() {
	const querySnapshot = await metricsDB.get();
	const data = querySnapshot.docs.map((doc) => ({
		...doc.data(),
		id: doc.id,
	}));
	return data;
}
