export const NUM_MARKERS = 24;
export const TIMER_LENGTH = 30;
export const EARNED_PER_WAYPOINT = 1000;
export const SPEND_PER_TICK = 5;

export const LOCATIONS = {
	truck: {
		lat: 37.779183,
		lng: -122.426798,
		zoom: 15,
	},
	bike: {
		lat: 37.779183,
		lng: -122.426798,
		zoom: 16,
	},
	walk: {
		lat: 37.779183,
		lng: -122.426798,
		zoom: 17,
	},
};

export const API_KEY = `AIzaSyA3gqF4a2G0bcRG7JgzAwo40iVStrSv2OM`;
export const FIREBASE_CONFIG = {
	apiKey: `AIzaSyCE0-ONW5HEyu4bcX12-w8Z0em-xjymoBc`,
	authDomain: `gmp-next-showcase-2020.firebaseapp.com`,
	databaseURL: `https://gmp-next-showcase-2020.firebaseio.com`,
	projectId: `gmp-next-showcase-2020`,
	storageBucket: `gmp-next-showcase-2020.appspot.com`,
	messagingSenderId: `628104223996`,
	appId: `1:628104223996:web:475c04c288af5b71f20b79`,
	measurementId: `G-512HN1V78W`,
};
